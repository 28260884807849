import './App.css';

function App() {
  return (
    <>
      <h1>ite missa est</h1>  
      <code>em desenvolvimento...</code>
    </>  
  );
}

export default App;
